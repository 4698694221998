div.course-public-view {
    display: grid;
    grid-template-areas: "info info info imge imge imge";
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

div.course-public-view-mobile {
    display: block;
}

div.course-info {
    grid-area: info;
    margin: 5px;
    margin-left: 10px;
}

div.course-info-mobile {
    margin: 5px;
    margin-left: 10px;
}

div.course-title {
    font-size: 40px;
    font-weight: 900;
}

div.course-description {
    font-family: 'Ubuntu';
    font-size: 20px;
    font-weight: 500;
    margin: 30px 15px;
    text-align: justify;
}

div.course-img {
    grid-area: imge;
    margin: 5px;
    border-radius: 10px;
}

div.course-img-mobile{
    margin: 5px;
    border-radius: 10px;
}

img.course-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: -5px 5px 15px 5px #6d6d6d;
}

div.caps-public {
    margin: 10px;
    border: 1px solid #8b8b8b;
    border-radius: 7px;
}

div.caps-title {
    border-radius: 6px 6px 0px 0px;
    border-bottom: 2px solid;
    background-color: #333;
    padding: 8px;
    font-size: 22px;
    color: #FFF;
    font-weight: 700;
}

div.MuiAccordion-root.course-cap-dropdouwn {
    border-radius: 0px 0px 6px 6px;
}

div.nivel-valoration {
    display: flex;
    margin-right: 5px;
}

.rating:hover {
    cursor: pointer;
}

.rating-data-hover:hover {
    cursor: pointer;
}

div.idioma-course {
    margin:2px;
    display:flex;
    align-items:center
}

div.idioma-course:hover {
    cursor: pointer;
}

div.video-public {
    margin: 10px;
    border: 1px solid #8b8b8b;
    border-radius: 7px;
}

div.video-title {
    border-radius: 6px 6px 0px 0px;
    border-bottom: 2px solid;
    background-color: #333;
    padding: 8px;
    font-size: 22px;
    color: #FFF;
    font-weight: 700;
}

div.private-course-view {
    display: grid;
    grid-template-areas: "time time main main main main main main";
    grid-template-columns: repeat(8, minmax(0, 1fr));
    height: 100%;
}

div.private-course-view-mobile {
    height: 100%;
}

div.timeline-view {
    grid-area: time;
    height: 100%;
}

div.timeline-view-mobile {
    height: 100%;
    border-right: 2px solid #333;
    max-width: 400px;
    min-width: 230px;
}

div.main-view {
    grid-area: main;
    margin: 0px 30px;
}

div.tema-title {
    font-size: 12px;
    color: #333;
}

div.part-title{
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 10px;
}

div.course-data-view {
    margin: 5px;
}

div.docs-download {
    margin: 5px;
    border: 1px solid #8b8b8b;
    border-radius: 5px;
}

div.docs-dwl-title {
    border-bottom: 2px solid;
    background-color: #708090;
    padding: 5px;
    font-size: 30px;
    color: #FFF;
    font-weight: 700;
}

div.docs-view {
    display: flex;
    flex-flow: row wrap;
}

span.nota-viwer {
    width: 300px;
    height: 300px;
}

div.tema-button {
    border-radius: 0px 50px 50px 0px;
    border: 1px solid #333;
    color: #333;
    height: 75px;
    width: 100%;
    background-color: white;
    align-content: center;
    font-size: 20px;
    font-weight: 500;
    transition: 0.2s all ease-in-out;
}

div.tema-button:hover {
    cursor: pointer;
    height: 100px;
    width: calc(100% + 10px);
    background-color: #708090;
    color: white;
}

div.disabled {
    border-radius: 0px 50px 50px 0px;
    border: 1px solid #333;
    color: #949494;
    height: 75px;
    width: 100%;
    background-color: rgb(224, 224, 224);
    align-content: center;
    font-size: 20px;
    font-weight: 500;
}

div.disabled:hover {
    border-radius: 0px 50px 50px 0px;
    border: 1px solid #333;
    color: #949494;
    height: 75px;
    width: 100%;
    background-color: rgb(224, 224, 224);
    align-content: center;
    font-size: 20px;
    font-weight: 500;
    cursor: auto;
}

div.finished {
    background-color: #36454f;
    border: 1px solid #000;
    color: #FFF;
    transition: 0.2s all ease-in-out;
}

div.finished:hover {
    background-color: #708090;
}

div.selected {
    height: 100px;
    width: calc(100% + 30px);
}

div.selected:hover {
    height: 100px;
    width: calc(100% + 30px);
}

div.is-drawer.selected {
    width: 100%;
}

div.is-drawer:hover {
    width: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

div.carousel-course-region {
    width: -webkit-fill-available;
    display: flex;
    min-height: 500px;
    background-color: #708090;
    border-radius: 0px 0px 30px 30px;
}

div.carousel-course-region.no-flex {
    width: -webkit-fill-available;
    display: block;
}

div.carousel-course-presentation {
    padding: 50px 20px 50px 100px;
    font-family: 'Ubuntu';
    width: 50%;
    font-size: 40px;
    font-weight: 600;
}

div.carousel-course-presentation.no-flex {
    padding: 50px 100px;
    font-family: 'Ubuntu';
    width: 100%;
    font-size: 40px;
    font-weight: 600;
}

img.carousel-presentation-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0px 0px 30px 0px;
}

img.carousel-presentation-img.no-flex {
    height: unset;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
}

div.carousel-extra-info {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

div.carousel-extra-info.no-flex {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    flex-flow: column;
    align-items: center;
}

div.carousel-extra-info .important-text {
    font-size: 28px;
    font-weight: 700;
}

div.carousel-extra-info .data-part {
    width: 30%;
}

div.carousel-extra-info .data-part.no-flex {
    width: -webkit-fill-available;
}