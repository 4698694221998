div.my-section {
    margin: 5px;
    border: 1px solid #8b8b8b;
    border-radius: 5px;
}

div.section-title {
    border-bottom: 2px solid;
    background-color: #708090;
    padding: 5px;
    font-size: 30px;
    color: #36454f;
    font-weight: 700;
}

div.section-title.expandable {
    display: flex;
}

div.section-body {
    min-height: 100px;
}

.grid {
    display: flex;
    flex-wrap: row wrap;
    justify-content: start;
}

div.clickable {
    cursor: pointer;
}

div.section-body-expandable {
    overflow: hidden;
    max-height: 0px;
    transition: max-height .5s;
}

div.section-body-expandable-expanded {
    height:auto;
    max-height:600px;
    transition: max-height .5s;
    overflow-y: scroll;
}