.title {
    font-size: 70px;
    padding: 0;
    margin: 0;
}

.subtitle {
    font-size: 45px;
    margin: 10px;
}

a.link {
    color: black;
    padding: 3px 0px;
    text-decoration: none;
    align-items: center;
    font-size: 26;
    font-weight: 600;
}

.center {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: start;
}