div.forum {
    display: flex;
    margin: 3px;
    border: 1px solid #536878;
    border-radius: 5px;
    padding: 5px;
    font-size: 20px;
}

div.forum:hover {
    cursor: pointer;
}

div.forum-name {
    font-weight: 700;
}

div.forum-view {
    border: 2px solid #536878;
    border-radius: 5px;
    background-color: #92a8bd;
}

div.forum-view-title {
    border-bottom: 1px solid #677986;
    font-size: 22px;
    font-weight: 800;
    padding: 10px;
}

div.forom-view-chat {
    padding: 5px;
    background-color: white;
    border-radius: 0px 0px 3px 3px;
}


div.respond-btn {
    margin: 5px;
    transition: all .1s;
    color: #36454f;
    font-weight: 700;
}

div.respond-btn.outlined {
    border: 2px solid #36454f;
    border-radius: 3px;
    padding: 5px;
}

div.respond-btn.forum-btn-disabled {
    border-color: #92a8bd;
    color: #92a8bd;
}

div.respond-btn:hover {
    cursor: pointer;
    color: #708090;
}

div.respond-btn.outlined:hover {
    border: 2px solid #708090;
}

div.respond-btn.forum-btn-disabled:hover {
    border-color: #92a8bd;
    color: #92a8bd;
    cursor: auto;
}

div.forum-message {
    margin-top: 5px;
    margin-bottom: 5px;
}

div.forum-message.principal {
    border-top: 2px solid #536878;
    border-bottom: 2px solid #536878;
}

div.forum-user {
    margin: 3px;
    display: flex;
    align-items: center;
}

a.user-link {
    color: black;
}

div.forum-username {
    font-size: 20px;
}

div.forum-teacher {
    font-size: 14px;
}

div.forum-msg {

}

div.forum-responses {

}

div.forum-send-msg {
    border: 1px solid #36454f;
    border-radius: 3px;
    padding: 5px;
    margin: 5px;
}

div.respond-close-btn {
    transition: all .1s;
    color: #36454f;
    font-weight: 700;
}

div.respond-close-btn:hover {
    cursor: pointer;
    color: #708090;
}