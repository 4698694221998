h1.title {
    font-size: 50px;
}

div.tickets-place {
    margin: 5px;
    border: 1px solid #8b8b8b;
    border-radius: 5px;
}

div.tickets-title {
    border-bottom: 2px solid;
    background-color: #708090;
    border-radius: 4px 4px 0px 0px;
    padding: 5px;
    font-size: 30px;
    color: #FFF;
    font-weight: 700;
}

div.tickets-view {
    display: block;
}

div.ticket-view {
    padding: 5px;
    display: flex;
}

div.ticket-view:hover {
    cursor: pointer;
    background-color: #e0e0e0;
    border-radius: 4px;
}

div.date-view {
    font-weight: 600;
}

div.title-view {
    font-weight: 500;
}

div.message-view {
    font-weight: 400;
}

div.ticket-box {
    border-radius: 5px;
    border: 1px solid #333;
    padding: 7px;
}

div.chat-view {
    border: 2px solid #333;
    border-radius: 5px;
    min-height: 100px;
}

div.message-write-view {
    background-color: #e9e9e9;
    padding: 5px;
    display: flex;
}

div.username-message:hover {
    cursor: pointer;
}