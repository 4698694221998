div.courseCard {
    padding: 5px;
    margin: 3px;
    border: 2px solid #1f3e6d;
    border-radius: 5px;
    color: #000;
    text-decoration: none;
    display: block;
    cursor: pointer;
    justify-items: left;
}

div a {
    display: block;
    color: white;
    padding: 5px;
    text-decoration: none;
    cursor: pointer;
}

div.my-courses {
    margin: 5px;
    border: 1px solid #8b8b8b;
    border-radius: 5px;
}

div.title {
    border-bottom: 2px solid;
    background-color: #708090;
    padding: 5px;
    font-size: 30px;
    color: #36454f;
    font-weight: 700;
}

div.courses-view {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}