html, body, #app, #app>div {
    height: 100%
}

#root {
    height: 100%;
}

.screen-body {
    height: inherit;
}

.filters {
    width: 200px;
    padding: 5px;
    background-color: #e7e7e7;
    -webkit-box-shadow: 5px 0px 17px 0px #000000; 
    box-shadow: 5px 0px 17px 0px #000000;
}

.main {
    margin: 0;
}

div.results {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

div.filter-title {
    font-size: 24;
    font-weight: 600;
}

div.no-results {
    text-align: center;
    margin: 10px;
    font-size: 22px;
    font-weight: 600;
}

div.small-info {
    font-size: 16px;
    font-weight: 400;
}